#contact {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;        
    }
    @media @u600 { 
        padding: 0px 5px;
        padding-bottom: 120px;   
    }
    #contact__form {
        padding-left: 50px;
        @media @u1400 { 
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin-bottom: 75px;
            padding: 0px 35px;
        }
    }
    #contact__img {
        padding-left: 50px;
        @media @u1400 { 
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin: 0px;
            padding: 0px 35px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    p {
        margin-top: 30px;
    }
    button {
        width: 50%;
        border: 1px solid @grey;
        height: 70px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        margin-top: 5px;
        background-color: @white;
        transition: all .3s ease;
        &#contact__telefon {
            border-right-width: 0px;
        }
        &:active {
            background-color: @white__darken;
        }
    }
    #bvzkont.hide {
        display: none;
    }
    #opt__phone, #opt__mail, #send, #response{
        display: none;
        &.act {
            display: block;
        }
    }
    #contactfrom.hide {
        display: none;
    }
    #send {
        background: @gradient;
        width: 100%;
        border: 0px solid transparent;
        color: @white;
    }
}