button {
    appearance: none;
    border: 0px solid white;
    font-size: 20px;
    line-height: 24px;
    font-family: Raleway, sans-serif;
    &:focus {
        outline: none;
    }
}
hr {
    display: block;
    margin: 0px;
    border: 0px solid white;
    height: 1px;
    background: @orange;
}
table, tbody, thead {
    width: 100%;
    margin: 0px;
    text-align: left;
}
table {
    margin-bottom: 40px;
}
th {
    font-weight: bold;
}
td, th {
    padding: 6px 0px;
    line-height: 22px;
}
td:nth-child(2) {
    text-align: right;
}
input {
    padding: 20px 15px;
    border: 1px solid @grey;
    display: block;
    width: 100%;
    font-family: Raleway, sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin-top: 5px;
    &:focus {
        border-color: @orange;
        outline: none;
    }
}
label {
    margin-top: 20px;
    display: block;
    font-weight: normal;
    color: @grey;
}