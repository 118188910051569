#cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 45px;
    margin-bottom: 100px;
    .card {
        padding: 25px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .card__overlay {
            background: @gradient__transparent;
            position: absolute;
            inset: 25px;
            .card__title {
                color: @white;
                width: 60%;
                font-weight: bold;
                line-height: normal;
                font-size: 50px;
                padding: 25px;
            }
        }
    }
    @media @u1400 {
        padding: 0 20px;
        .card { 
            padding: 15px;
            .card__overlay {
                inset: 15px;
                .card__title {
                    font-size: 40px;
                }
            }
        }
    }
    @media @u1040 {
        grid-template-columns: 1fr;
        max-width: 500px;
        margin: 0 auto;
    }
    @media @u600 {
        padding: 0;
        .card {
            .card__overlay {
                .card__title {
                    padding: 15px;
                }
            }
        }
    }
}