header {
    display: block;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background-color: @white;
    text-align: right;
    box-shadow: 0px 3px 10px rgba(0,0,0,0);
    transition: box-shadow .7s ease;
    @media @u600 {
        height: 50px;
    }
    &.act {
        box-shadow: 0px 10px 30px rgba(0,0,0,0.15);
    }
}
#logo {
    display: block;
    position: fixed;
    top: 0;
    left: 70px;
    height: 80px;
    @media @u1400 {
        left: 85px;
    }
    @media @u1040 {
        left: 55px;
    }
    @media @u600 {
        left: 35px;
        height: 50px;
    }
    img {
        height: 50px;
        margin-top: 15px;
        width: auto;
        @media @u600 {
            margin-top: 5px;
            height: 40px;
        }
    }
}
#menu {
    display: inline-block;
    vertical-align: top;
    #burger {
        display: block;
        height: 80px;
        width: 95px;
        padding: 32px 35px;
        cursor: pointer;
        background-color: @white;
        transition: all .3s ease;
        @media @u600 {
            height: 50px;
            padding: 10px 35px;
        }
        span {
            display: block;
            position: relative;
            background-color: @red;
            width: 25px;
            height: 3px;
            margin-bottom: 5px;
            transition: all .3s ease;
            &:nth-child(3) {
                margin-bottom: 0px;
            }
        }
        &.act {
            span:nth-child(1) {
                transform: rotate(45deg) translate(4px, 5px);
            }
            span:nth-child(2) {
                transform: rotate(-45deg) translate(1px,-1px);
            }
        }
        &:hover span {
            background-color: @red__lighten;
        }
        &:active {
            background-color: @white__darken;
            span {
                background-color: @red__darken;
            }
        }
    }
}
#cta {
    display: inline-block;
    vertical-align: top;  
    @media @u600 {
        position: fixed;
        bottom: 0px;
        left: 0px;
        right: 0px;
        box-shadow: 0px 0px 30px rgba(0,0,0,0.15);
    }
}
#cta__button {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    cursor: pointer;
    height: 80px;
    padding: 30px 60px;
    background: @gradient;
    color: @white;
    &:hover {
        background: @gradient__lighten;
    }
    &:active {
        background: @gradient__darken;
    }
    @media @u600 { 
        width: 100%;
        height: 50px;
        padding: 17px;
        justify-content: flex-end;
    }
} 