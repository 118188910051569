#drive {
    display: block;
    position: relative;
    padding: 120px 70px;
    @media @u1400 { 
        padding: 100px 35px;
        padding-bottom: 120px;
    }
    @media @u600 { 
        padding: 60px 0;
        padding-bottom: 60px;   
    }
    #drive__text {
        padding-left: 50px;
        @media @u1400 {
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin: 0px;
            margin-bottom: 75px;
            padding: 0px 35px;
        }
        @media @u600 {
            padding: 0;
            margin: 0 15px 60px;
            width: auto;
        }
    }
    #drive__map {
        padding-left: 50px;
        @media @u1250 {
            padding-left: 35px;
        }
        @media @u1040 {
            width: 100%;
            margin: 0px;
            padding: 0px 35px;
        }
        @media @u600 {
            width: auto;
            height: auto;
            margin: 15px;
            padding: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    p {
        margin-top: 30px;
    }
}