#booking {
    position: fixed;
    display: flex;
    top: 80px;
    @media @u600 {
        top: 50px;
    }
    left: 0px;
    width: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, .9);
    text-align: left;
    backdrop-filter: blur(2px);
    padding: 70px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    box-shadow: 0 10px 30px rgba(0,0,0,.15);
    &.act {
        opacity: 1;
        visibility: visible;
    }
    #booking__headline p {
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        letter-spacing: 0.8px;
        position: relative;
        display: block;
        margin-bottom: 30px;
        span {
            display: block;
            font-weight: normal;
        }
    }
    #booking__content {
        position: relative;
        display: block;
        width: 100%;
    }
    #booking__menu {
        display: flex;
    }
    a {
        display: block;
        position: relative;
        padding: 50px 50px;
        width: 100%;
        background: @gradient;
        color: @white;
        margin-left: 20px;
        font-size: 20px;
        line-height: 24px;
        &:hover {
            opacity: .7;
            text-decoration: none;
        }
        &:nth-child(1) {
            margin-left: 0px;
        }
    }
}