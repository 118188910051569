#atv {
    display: block;
    position: relative;
    padding-bottom: 120px;
    @media @u1250 {
        padding-bottom: 75px;
    }
    @media @u830 {
        padding-bottom: 0px;
    }
    #atv__img {
        display: block;
        position: relative;
        width: 100%;
        height: calc(100vh - 290px);
        min-height: 33vw;
        object-fit: cover;
        @media @u830 {
            height: calc(100vh - 480px);
        }
    }
    #atv__title {
        padding: 0px 70px;
        display: flex;
        position: relative;
        @media @u1400 {
            padding: 0px 35px;
        }
        @media @u1040 {
            padding: 0px;
        }
        @media @u830 {
            display: block;
        }
        #title__headline {
            width: calc(66.66% - 40px);
            height: 230px;
            background: @gradient;
            padding: 0 50px;
            margin-top: -40px;
            display: flex;
            align-items: center;
            margin-right: 40px;
            @media @u1040 { 
                width: 100%;
                flex: 1 1 100%;
                margin-right: 0px;
            }
            @media @u830 {
                padding: 85px 35px;
                margin-top: 0px;
                height: auto;
            }
            h1 {
                font-size: 30px;
                line-height: 35px;
                font-weight: bold;
                color: @white;
                letter-spacing: 0.8px;
                span {
                    font-weight: normal;
                    display: block;
                }
                @media @u830 {
                    max-width: 530px;
                    margin: 0px auto;
                    display: block;
                }
            }
        }
        #title__stats {
            width: 33.33%;
            height: 190px;
            padding: 0px 50px;
            display: flex;
            align-items: center;
            @media @u1250 { 
                min-width: 370px;
            }
            @media @u1040 { 
                width: 280px;
                min-width: 280px;
                display: block;
                padding: 50px 50px 0px 50px;
            }
            @media @u830 {
                display: flex;
                max-width: 600px;
                width: 100%;
                margin: 0px auto;
                padding: 0px 35px;
            }
            @media @u600 {
                flex-direction: column;
                justify-content: center;
                padding: 0 15px;
            }
            div {
                width: 50%;
                position: relative;
                @media @u1040 { 
                    width: 100%;
                }
                @media @u830 { 
                    width: 50%;
                }
                @media @u600 {
                    width: 100%;
                }
                &#fairgrounds {
                    @media @u1040 { 
                        margin-top: 15px;
                    } 
                    @media @u830 { 
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}