#grundrisse_wrap {
    width: 100%;
    text-align: left;
    padding: 0px 70px 0px 70px;
    max-height: 0px;
    height: auto;
    overflow: hidden;
    transition: all .7s ease;
    @media @u1400 {
        padding: 0px 70px 0px 70px;
    }
    @media @u1040 {
        padding: 0px 35px;
        max-width: 600px;
        margin: 0px auto;
    }
    &.act {
        max-height: 10000px;
        padding-bottom: 140px;
        @media @u1040 {
            padding-bottom: 75px;
        }
    }
    #grundrisse {
        display: flex;
        justify-content: space-between;
        margin: 0px -10px;
        @media @u1040 {
            flex-wrap: wrap;
            margin: 0px 0px;
        }
    }
    .grundriss {
        margin: 0px 10px;
        img {
            width: auto;
            max-width: 100%;
        }
        p {
            margin-left: 10px;
        }
        @media @u1040 {
            width: 50%;
            margin: 0px 0px;
        }
    }
    #grundriss__headline p{
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        letter-spacing: 0.8px;
        position: relative;
        display: block;
        margin-bottom: 30px;
        padding-left: 35px;
        span {
            display: block;
            font-weight: normal;
        }
    }
}