#intro {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        display: flex;
        flex-direction: column;
        padding-bottom: 0px;
    }
    @media @u600 { 
        padding: 0px 15px;
    }
    >div {
        padding-left: 50px;
        @media @u1400 { 
            padding-left: 35px;
        } 
    }
    #intro__text{
        @media @u1250 {
            order: 3;
            width: 100%;
            max-width: 600px;
            margin-bottom: 75px;
        }
        @media @u1040 {
            margin: 0px auto;
            margin-bottom: 75px;
            padding: 0px 35px;
        }
        @media @u600 { 
            padding: 0px;
        }
    }
    #intro__facts{
        @media @u1250 {
            order: 2;
            margin: 0px;
            width: 100%;
            margin-bottom: 75px;
        }
        @media @u1040 {
            padding: 0px 35px;
        }
        @media @u600 { 
            padding: 0px 20px;
        }
    }
    #intro__stats {
        border: 1px solid @orange;
        padding: 50px 50px 20px 50px;
        @media @u1400 { 
            padding: 35px 35px 15px 35px;
        }
        @media @u1250 {
            order: 1;
            margin: 0px;
            width: 100%;
            margin-bottom: 75px;
        }
        @media @u600 { 
            padding: 15px 15px 15px 15px;
        }
        img {
            width: 80px;
            margin: 0px auto;
        }
    }
    p {
        margin-top: 30px;
    }
    #facts {
        margin-top: 30px;
        max-width: 400px;
        @media @u1250 {
            width: 100%;
            max-width: 100%;
            display: flex;
            flex-wrap: wrap;
            li {
                width: calc(100% / 3);
                padding-right: 30px;
            }
        }
        @media @u1040 {
            li {
                width: 100%;
                max-width: 400px;
                padding: 0px;
            }
        }
    }
    hr {
        margin-bottom: 30px;
    }
    .seats, .equipment {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        margin-bottom: 30px;
        @media @u1250 {
            width: calc(100% / 3);
            &.seats {
                width: calc(100% / 4);
            }
        }
        @media @u1040 {
            &.seats {
                width: calc(100% / 2);
            }
        }
        @media @u830 {
            width: 100%;
            &.seats {
                width: calc(100% / 2);
            }
        }
    }
    .equipment img {
        display: inline-block;
        vertical-align: middle;
        width: 70px;
        height: 70px;
        margin-right: 10px;
    }
    .equipment p {
        display: inline-block;
        vertical-align: middle;
        margin: 0px;
        width: auto;
        max-width: 145px;
        min-width: 100px;
    }
}
#grundsitz {
    background: @gradient;
    display: block;
    cursor: pointer;
    height: 80px;
    padding: 20px 20px;
    color: #fff;
    width: 100%;
    margin-bottom: 30px;
}