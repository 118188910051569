#impressum {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    h1 {
        font-size: 30px;
        line-height: 35px;
        font-weight: 700;
        letter-spacing: .8px;
    }
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        padding-bottom: 0px;
    }
    @media @u600 { 
        padding: 0px 5px;
    }
}