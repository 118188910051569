#friends {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    margin-left: 50px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        padding-bottom: 0px;
    }
    @media @u600 { 
        padding: 0px 5px;
        margin: 15px;
    }
    &>div {
        h3 {
            padding-right: 0px;
        }
    }
    h2 {
        margin-bottom: 25px;
    }
    .col {
        @media @u1250 {
            width: 100%;
            max-width: 600px;
            margin: 0px;
            display: block;
            margin-bottom: 75px;
        }
    }
    img {
        margin: 30px 0px 15px 0px;
        height: 40px;
        width: auto;
        &#czernys_logo {
            height: 80px;
            margin-left: -20px;
        }
        &#plattenmonster_logo {
            @media @u400 {   
                width: 100%;
                height: auto;
            }
        }
    }
}