#offer, #offer__overlay {
    position: relative;
    display: block;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        padding-bottom: 0px;
    }
    @media @u600 { 
        padding: 0px 15px;
    }
    >div {
        border: 1px solid @orange;
        h3 {
            min-height: 84px;
            padding-right: 80px;
        }
    }
    .col { 
        @media @u1250 {
            width: 100%;
            margin: 0px;
            margin-bottom: 75px;
        }
    }
    .offer__detail {
        padding: 25px;
        border-right: 1px solid @orange;
        &:last-child {
            border: none;
        }
        .offer__subtitle {
            font-weight: 200;
            min-height: 0;
            margin-bottom: 25px;
        }
        .icons {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 25px;
            column-gap: 25px;
            .icon__wrap {
                display: flex;
                align-items: center;
                .icon__element {
                    min-width: 50px;
                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                }
                .detail__wrap {
                    :first-child {
                        font-weight: bold;
                    }
                }
            }
        }
        @media @u1500 {
            .icons {
                display: grid;
                grid-template-columns: 1fr;
            }
        }
        @media @u1040 {
            border-bottom: 1px solid @orange;
            border-right: none;
        }
        @media @u600 {
            padding: 10px;
            .offer__title {
                margin-top: 25px;
            }
            .offer__subtitle {
                padding: 0;
            }
            .icon__element {
                display: none;
                .icon {
                    display: none;
                }
            }
        }
    }
    .offer__header {
        padding: 40px 50px 0px 50px;
        margin-bottom: 20px;
        @media @u1400 {
            padding: 25px 35px 0px 35px;
        }
        background: @white;
    }
    .offer__content {
        padding: 0px 50px;
        @media @u1400 {
            padding: 0px 35px;
        }
    }
    .price {
        font-family: Lato, sans-serif;
        font-weight: bold;
        font-size: 30px;
        line-height: 36px; 
        position: absolute;
        right: 0px;
        bottom: 14px;
        text-align: right;
        span {
            font-weight: lighter;
            font-family: Raleway, sans-serif;
        }
        @media @u600 {
            position: relative;
            padding-top: 10px;
            text-align: left;
            span {
                display: inline-block;
            }
        }
    }
    #offer__daily {
        padding: 0px;
        margin-top: -20px;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.15);
    }
    #offer__daily__wrap {
        padding: 20px 50px 0px 50px;
        @media @u1400 {
            padding: 20px 35px 0px 35px;
        }
    }
    .offer__description {
        min-height: 150px;
        padding-bottom: 15px;
        @media @u1250 {
            min-height: 0px;
            max-width: 600px;
        }
    }
    .offer__roomsize {
        margin-bottom: 40px;
        @media @u1250 {
            margin-right: 40px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .offer__fairgrounds {
        @media @u1250 {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    table {
        @media @u1250 {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding-right: 40px;
            thead, tbody {
                width: 100%;
                display: table;
            }
        }
        @media @u1040 {
            display: table;
            width: 100%;
            padding-right: 0px;
        }
    }
    .texted td {
        @media @u600 {
            display: block;
            text-align: left;
            &:nth-child(1) {
                font-weight: bold;
            }
        }
    }
    tr {
        @media @u600 {
            margin-bottom: 5px;
        }
    }
    hr {
        @media @u1250 {
            margin-bottom: 20px;
        }
    }
    #offer__size {
        >p {
            width: 50%;
            display: inline-block;
            vertical-align: top;
            @media @u1250 {
                width: auto;
            }
        }
    }
    .offer__daily__title {
        margin: -1px -1px 0px -1px;
        padding: 50px 50px 12px 50px;
        @media @u1400 {
            padding: 40px 35px 12px 35px;
        }
        background: @gradient;
        color: @white;
    }
}
#offer__overlay {
    position: fixed;
    top: -100%;
    width: 100%;
    left: 0px;
    display: block;
    padding: 0px 70px;
    border-top: 0px solid @white;
    @media @u1400 { 
        padding: 0px 35px;
    }
    @media @u1250 {
        display: none;
    }
    &.act {
        top: 80px;
    }
    &>div {
        border: 0px solid transparent;
    }
    .col {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
    }
    .offer__daily__title {
        margin: -1px -1px 0px -1px;
        padding: 30px 50px 12px 50px;
        @media @u1400 {
            padding: 20px 35px 12px 35px;
        }
        background: @gradient;
        color: @white;
        opacity: 0;
        transition: opacity .3s ease;
        &.act {
            opacity: 1;
        }
    }
    .offer__header {
        opacity: 0;
        transition: opacity .3s ease;
        &.act {
            opacity: 1;
        }
    }
}