#faqs {
    display: block;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u600 { 
        padding: 0px 5px;
        padding-bottom: 120px;   
    }
    &>div {
        padding-left: 50px;
        @media @u1400 {
            padding-left: 35px;
        }
        h3 {
            padding-right: 0px;
        }
    }
    .col {
        @media @u1250 {
            width: 100%;
            max-width: 600px;
            display: block;
            margin-bottom: 75px;
        }
    }
    img {
        margin: 30px 0px 15px 0px;
        height: 40px;
        width: auto;
        &#czernys_logo {
            height: 80px;
            margin-left: -20px;
        }
    }
    .faq__title {
        line-height: 24px;
        padding-top: 15px;
        padding-bottom: 5px;
        display: block;
        cursor: pointer;
    }
    .faq__content {
        max-height: 0px;
        height: auto;
        overflow: hidden;
        border-bottom: 1px solid @orange;
        transition: all .3s ease;
        p {
            padding: 10px 0px;
        }
    }
    .faq.act .faq__content {
        max-height: 500px;
    }
}