footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0px 70px;
    padding-bottom: 120px;
    @media @u1400 { 
        padding: 0px 35px;
        padding-bottom: 120px;
    }
    @media @u1250 {
        padding-bottom: 60px;
    }
    @media @u600 { 
        padding: 0px 5px 120px 5px;
        flex-direction: column;
        &>div {
            margin-bottom: 60px;
            a {
                margin: 0 20px;
            }
        }
    }
    &>.legals {
        padding-left: 50px;
        @media @u1400 {
            padding-left: 35px;
        }
        @media @u600 {
            padding-left: 0;
        }
        h3 {
            padding-right: 0px;
        }
    }
    .col {
        @media @u1250 {
            width: 100%;
            max-width: 600px;
            margin: 0px;
            display: block;
            margin-bottom: 75px;
        }
        @media @u1040 {
            padding: 0px 35px;
        }
    }
    a {
        margin-right: 20px;
    }
    .mm__wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        @media @u600 {
            align-items: center;
        }
        p {
            font-size: .75em;
        }
        .mm__signature {
            height: 1em;
            width: auto;
        }
    }
}