#banner {
    margin: 0 70px;
    max-height: 500px;
    overflow: hidden;
    @media @u600 {
        margin: 0 15px;
    }
    img {
        height: 400px;
        object-fit: cover;
    }
}