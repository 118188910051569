@font-face {
    font-family: "Raleway";
    font-display: block;
    font-weight: 300;
    src: url("https://cdn.mmsrv.de/fonts/Raleway/Regular.ttf") format('truetype');
}
@font-face {
    font-family: "Raleway";
    font-display: block;
    font-weight: 700;
    src: url("https://cdn.mmsrv.de/fonts/Raleway/Bold.ttf") format('truetype');
}
@font-face {
    font-family: "RalewayItalic";
    font-display: block;
    font-weight: 300;
    src: url("https://cdn.mmsrv.de/fonts/Raleway/Italic.ttf") format('truetype');
}
@font-face {
    font-family: "Lato";
    font-display: block;
    font-weight: 700;
    src: url("https://cdn.mmsrv.de/fonts/Lato/Bold.ttf") format('truetype');
}