#navigation {
    position: fixed;
    display: flex;
    top: 80px;
    @media @u600 {
        top: 49px;
    }
    left: 0px;
    width: 100%;
    z-index: 999;
    background: rgba(255, 255, 255, .9);
    text-align: left;
    backdrop-filter: blur(2px);
    padding: 70px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
    box-shadow: 0 10px 30px rgba(0,0,0,.15);
    @media @u1250 {
        padding: 35px;
    }
    &.act {
        opacity: 1;
        visibility: visible;
    }
    &.scroll {
        bottom: 0px;
        overflow: scroll;
    }
    #navigation__headline p {
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        letter-spacing: 0.8px;
        position: relative;
        display: block;
        span {
            display: block;
            font-weight: normal;
        }
    }
    #navigation__content {
        position: relative;
        display: block;
        width: 100%;
    }
    ul {
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        @media @u1250 {
            display: block;
        }
    }
    li {
        font-size: 16px;
        line-height: 24px;
        width: 100%;
    }
    a {
        display: block;
        position: relative;
        padding: 12px 0px;
    }
    #navigation__socialmedia {
        margin-top: 30px;
        display: flex;
        a {
            margin-right: 20px;
        }
        img {
            height: 20px;
            width: 30px;
        }
    }
}