.row .col {
    width: calc(100% / 3 - 80px / 3);
    display: inline-block;
    vertical-align: top;
    @media @u1040 {
        width: 100%;
    }
    &.col__left {
        margin-right: calc(40px / 3 * 2);
        @media @u1040 {
            margin-right: 0px;
        }
    }
    &.col__mid {
        margin-right: calc(40px / 3);
        margin-left: calc(40px / 3);
        @media @u1040 {
            margin-right: 0px;
            margin-left: 0px;
        }
    }
    &.col__right {
        margin-left: calc(40px / 3 * 2);
        @media @u1040 {
            margin-left: 0px;
        }
    }
    &.col__midright {
        margin-left: calc(40px / 3);
        width: calc(100% / 3 * 2 - 80px / 6);
        @media @u1040 {
            margin-left: 0px;
            width: 100%;
        }
    }
    &.col__all {
        width: 100%;
        &.col__offer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding: 25px;
            @media @u1040 {
                grid-template-columns: 1fr;
            }
            @media @u600 {
                padding: 10px;
            }
        }
    }
    
}
.row {
    @media @u1040 {
        max-width: 670px;
        margin: 0px auto;
    }
}